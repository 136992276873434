export const environment = {
  production: true,
  environment: 'prod',
  appUrl: 'https://ect2.production.zonarsystems.net/',
  audience: 'https://api.zonarsystems.net',
  clientID: 'vgrTtePDc8E1kPdRlp9X2Fe3JX7DO2zB',
  domain: 'zonar-login.auth0.com',
  applicationId: 'f87a31a8-3839-42cd-b958-73235a487dca',
  defaultPermPrefix: 'ect2',
  redirectUrl: '/',
  defaultZonarRole: '74c78f51-8158-4207-a260-7a8027d9b845',
  companyApi: 'https://api.zonarsystems.net/core/v1beta2',
  configApi: 'https://api.zonarsystems.net/ib-api/v1',
  evirApi: 'https://api.zonarsystems.net/evir/v1',
  region: 'NA',
  feature_flags: {
    isNewLanguageDictionary: false,
  },
  copyRightText: 'Zonar Systems',
  datadog: {
    enabled: true,
    applicationId: '6771fe23-e504-437a-b2cf-79d74fea313f',
    clientToken: 'pub1cc0c05dd55624b135b865328e6ca0a3',
    site: 'us5.datadoghq.com',
    service: 'inspection-builder',
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    excludedActivityUrls: ['localhost', '172.0.0.1'],
  },
  i18n: {
    supportedLanguages: ['en', 'en-GB', 'es', 'de', 'fr', 'it'],
    defaultLanguage: 'en',
    useBrowserLanguage: true,
  },
  pendo: {
    url: `https://cdn.pendo.io/agent/static/`,
    key: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
  },
}
